/* @import url("https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&family=Noto+Sans&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Orienta&family=Quicksand:wght@500&display=swap");

body {
  /* background: #212324; */
  background: #d8dfed;
}

* {
  /* font-family: "Carrois Gothic SC", sans-serif;
  font-family: "Noto Sans", sans-serif; */
  font-family: "Orienta", sans-serif;
  box-sizing: border-box;
  margin: 0;
  position: relative;
}

h1,
h2,
h3 {
  font-family: "Quicksand", sans-serif;
}

.hoverImg {
  display: none;
}